import * as React from 'react'
import ComingSoon from '../components/coming-soon/coming-soon'

const OurTeamPage = () => {
    return (
        <ComingSoon></ComingSoon>
    )
  }
export default Object.assign(OurTeamPage, {
      pageTitle: 'Our team'
  })