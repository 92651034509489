import * as React from 'react';

const ComingSoon = () => {
    return (
        <div className="coming-soon fg-primary">
            <svg xmlns="http://www.w3.org/2000/svg">
                <filter id="motion-blur-filter" filterUnits="userSpaceOnUse">
                    <feGaussianBlur stdDeviation="100 0"></feGaussianBlur>
                </filter>
            </svg>
            <p filter-content="C" className='coming-soon__text'>Coming Soon....</p>
        </div>
    );
}

export default ComingSoon;